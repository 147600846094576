<template>
  <main class="news-page">
    <BreadcrumbsComponent title="Новости" :links="links" />
    <div class="container-padding">
      <div class="news-page__inner page__inner">
        <div class="news-page__filter filters">
          <SelectComponent
            v-model="form.type.value"
            :errors="form.type.errors"
            :options="news_types"
            label-name="title"
            title="Тип новости"
            placeholder="Все"
          />
          <InputComponent
            v-model.trim="form.title.value"
            :errors="form.title.errors"
            title="Поиск по заголовку"
          >
            <template v-slot:action>
              <IconComponent name="search-normal" />
            </template>
          </InputComponent>
          <DateComponent
            v-model="form.published_at.value"
            emptyPlaceholder="Любая"
            mode="date"
            range
            title="Дата публикации"
          />
          <div class="news-page__actions">
            <button class="btn btn--lg btn--outline" @click="updatePage">Поиск</button>
            <button class="btn btn--lg btn--main" @click="resetPage">Сбросить</button>
          </div>
        </div>
        <div class="news-page__counter">
          <span>Найдено {{ total ? total : 0 }} записей</span>
        </div>
        <ul v-if="news && news.length" class="news-page__list">
          <li v-for="(item, i) in news" :key="i">
            <CardComponent :item="item" />
          </li>
        </ul>
        <p v-else>В данный момент информация отсутствует</p>
        <PaginationComponent
          v-if="Math.ceil(total / first) > 1 && !loading"
          :page="form.page.value"
          :total="Math.ceil(total / first)"
          @change="updatePagination"
        />
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import DateComponent from "components/inputs/DateComponent.vue";
import PaginationComponent from "components/PaginationComponent.vue";
import CardComponent from "components/CardComponent.vue";
import DEFAULT from "gql/queries/default.graphql";
import NEWS_PAGE from "gql/pages/NewsPage.graphql";
import moment from "moment/moment";

/**
 * Парсим параметры с роутера
 **/
const resolver = (queries) => ({
  title: {
    value: queries?.title,
    error: [],
  },
  published_at: {
    value: queries.published_at ? JSON.parse(queries.published_at) : undefined,
    error: [],
  },
  type: {
    value: queries.type ? JSON.parse(queries.type) : undefined,
    error: [],
  },
  page: {
    value: queries.page ? parseInt(queries?.page) : 1,
  },
});
export default {
  name: "NewsPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: DEFAULT,
      })
      .then(({ data }) => {
        store.dispatch("save", data);
      })
      .catch(() => {});
  },
  created() {
    /**
     * Парсим параметры с роутера и сохраняем в this.form в нужном нам формате
     **/
    this.form = { ...resolver(this.$route.query) };
    this.get();
  },
  data() {
    return {
      first: 12,
      loading: false,
      form: {
        title: {
          value: undefined,
          errors: [],
        },
        published_at: {
          value: undefined,
          errors: [],
        },
        type: {
          value: undefined,
          errors: [],
        },
        page: {
          value: 1,
          error: [],
        },
      },
    };
  },
  methods: {
    resolver,
    resetPage() {
      this.form.title.value = undefined;
      this.form.type.value = undefined;
      this.form.published_at.value = undefined;
      this.form.page.value = 1;
      /** Не говнокод а решение **/
      this.$router.push({ name: "news" }).catch(() => {});
      this.get();
    },
    updatePage() {
      /**
       * Проверяем если параметры в this.form поменялись то обновляем роутер и делаем запрос на получение данных
       * если нет то выходим из функции
       **/
      if (JSON.stringify(this.form) === JSON.stringify(resolver(this.$route.query))) return;
      this.$router.push({ name: "news", query: { ...this.toQuery() } });
      this.get();
    },
    toQuery() {
      return {
        title: this.form.title.value,
        type: JSON.stringify(this.form.type.value),
        published_at: JSON.stringify(this.form.published_at.value),
        page: this.form.page.value,
      };
    },
    updatePagination($event) {
      this.form.page.value = parseInt($event);
      this.updatePage();
    },
    get() {
      if (!this.loading) {
        this.loading = true;
        let variables = {
          first: this.first,
          page: this.form.page.value,
          title: this.form.title.value,
          type: this.form.type.value?.id,
          published_at_start_date: this.form.published_at.value
            ? moment(this.form.published_at.value.start).format("YYYY-MM-DD HH:mm:ss")
            : undefined,
          published_at_end_date: this.form.published_at.value
            ? moment(this.form.published_at.value.end).format("YYYY-MM-DD HH:mm:ss")
            : undefined,
        };
        this.$apollo.provider.defaultClient
          .query({
            query: NEWS_PAGE,
            variables,
          })
          .then(({ data }) => {
            this.$store.dispatch("news/save", data);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
  computed: {
    links() {
      return [
        {
          title: "Пресс-центр",
          to: { name: "press" },
        },
        {
          title: "Новости",
          to: { name: "news" },
        },
      ];
    },
    news_types() {
      return this.$store.state.government_news_types;
    },
    news() {
      return this.$store.state.news.news_paginate?.data;
    },
    total() {
      return this.$store.state.news.news_paginate?.paginatorInfo?.total;
    },
  },
  components: {
    BreadcrumbsComponent,
    IconComponent,
    InputComponent,
    SelectComponent,
    DateComponent,
    PaginationComponent,
    CardComponent,
  },
};
</script>

<style lang="stylus">

.news-page {
  padding-bottom 60px

  &__filter {
    grid-template-columns: 280fr 400fr 240fr 220fr;

    +below(1100px) {
      grid-template-columns 0.5fr 0.5fr
      grid-gap: 20px;
    }

    +below(620px) {
      grid-template-columns 1fr
    }
  }

  &__inner {
    display flex
    flex-direction column
    gap 40px
  }
  &__actions {
    display flex
    gap 15px
    align-items center
  }

  &__counter {
    font-family: Inter;
    font-size: 0.875em
    font-style: normal;
    font-weight: 400;
    line-height: 22px
    color var(--main-1-dark-50)
  }

  &__list {
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 40px
    +below(1300px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(900px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(600px) {
      gap 20px
    }
    +below(550px) {
      grid-template-columns repeat(1, 1fr)
    }
  }
}
</style>
